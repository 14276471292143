import { Box } from '@components/box/box'
import { Button } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { useEffect, useState } from 'react'
import { EmailNotificationAPI } from 'src/services/email-notification/email-notification.api'
import { ToastService } from 'src/services/toast/toast.service'
import { UserAPI } from 'src/services/user/user.api'

import { EndUserOffboardingStatus, EndUserProps } from '../../../../services/user/user.types'

function DocsAndForms(props: { userToEdit: EndUserProps; currentUser: EndUserProps | null }) {
	const [offboardingStatus, setOffboardingStatus] = useState<EndUserOffboardingStatus>()
	const [showOffboardingModal, setShowOffboardingModal] = useState<boolean>(false)

	function getOffboardingStatus(endUserId: number) {
		UserAPI.getOffboardingStatus(endUserId).then((res) => {
			setOffboardingStatus(res.data)
		})
	}

	useEffect(() => {
		getOffboardingStatus(props.userToEdit.endUserId)
	}, [showOffboardingModal])

	async function offboardUser(endUserId: number) {
		await EmailNotificationAPI.offboardingRequest(endUserId).then(() => {
			setShowOffboardingModal(false)
		})
	}

	async function offboardUserStayLicensed(endUserId: number) {
		await EmailNotificationAPI.offboardingRequestStayLicensed(endUserId).then(() => {
			setShowOffboardingModal(false)
			ToastService.create({ type: 'SUCCESS', body: `Your request has been completed` })
		})
	}

	return (
		<div>
			<Typography type="h2" margins={['bottom']}>
				Docs and Forms
			</Typography>
			<div style={{ maxWidth: `800px` }} className="mb-40">
				<Paper bgColor="primary" padding={['all']} marginSize="section" margins={['bottom', 'right']}>
					<Typography type="h3" margins={['top']}>
						W9 Form
					</Typography>
					<p>
						If you've recently changed your name, business name, or address, you will need to fill out an
						updated W-9 form. It helps ensure accurate tax reporting for businesses and organizations that
						may need your updated information. Once your updated W-9 form is completed, please send it to{' '}
						<a href="mailto:billing@lokationre.com">billing@lokationre.com</a>.
					</p>

					<Button
						variant="contained"
						size="lg"
						primary={true}
						label={`Download W-9`}
						onClick={() => {
							window.open('https://www.irs.gov/pub/irs-pdf/fw9.pdf', '_blank')
						}}
					/>
				</Paper>

				<Paper bgColor="primary" padding={['all']} marginSize="section" margins={['bottom', 'right']}>
					<Typography type="h3" margins={['top']}>
						Exit Form
					</Typography>
					<Typography type="h4" margins={['top']}>
						Not the right fit?
					</Typography>

					{offboardingStatus && offboardingStatus.adminNotified ? (
						<>
							<div className="mt-10 mb-20">
								<em>
									You have requested more information about offboarding on{' '}
									{new Date(offboardingStatus.adminNotifiedTimestamp).toLocaleDateString('en-US', {
										year: 'numeric',
										month: 'long',
										day: 'numeric',
									})}
									{' at '}
									{new Date(offboardingStatus.adminNotifiedTimestamp).toLocaleTimeString('en-US', {
										hour: '2-digit',
										minute: '2-digit',
										hour12: true,
									})}
									. Response times may very, but please send us a note at{' '}
									<a href="mailto:programs@lokationre.com">programs@lokationre.com</a> if you do not
									hear from us within <strong>48 hours</strong>.
								</em>
							</div>
							{offboardingStatus.instructionsSent && (
								<div className="mt-10 mb-20">
									<em>
										Our team has sent you an email with instructions to finish offboarding on{' '}
										{new Date(offboardingStatus.instructionsSentTimestamp).toLocaleDateString(
											'en-US',
											{
												year: 'numeric',
												month: 'long',
												day: 'numeric',
											},
										)}
										{' at '}
										{new Date(offboardingStatus.instructionsSentTimestamp).toLocaleTimeString(
											'en-US',
											{
												hour: '2-digit',
												minute: '2-digit',
												hour12: true,
											},
										)}
										. Please check your inbox at the following email: {props.userToEdit.email}
									</em>
								</div>
							)}
						</>
					) : (
						<div>
							<p>
								Look we get it. Needs change, but our door is always open! In order to start
								offboarding, click the button below to send a message to our offboarding department.
							</p>
							<Button
								variant="contained"
								size="lg"
								primary={true}
								label={'Request Offboarding Info'}
								onClick={() => setShowOffboardingModal(true)}
								margins={['bottom']}
							/>
						</div>
					)}
				</Paper>
				{showOffboardingModal && (
					<Modal
						maxWidth={500}
						maxHeight={400}
						fixedHeight={false}
						onClose={() => setShowOffboardingModal(false)}
					>
						<ModalBody>
							<Typography type="h4" variant={['center']} margins={['bottom']}>
								Stay Licensed, Avoid MLS Fees.
							</Typography>

							<Typography type="normal" variant={['center']} margins={['bottom']}>
								Trying to avoid the MLS Fees? Keep your license and skip the fees. You wont be able to
								access the MLS but your license will be active and you can still earn commissions. Click
								the button below to speak with someone about this.
							</Typography>

							<Box flex="row" justifyContent="center" className="mb-30">
								<Button
									variant="outlined"
									size="lg"
									primary={false}
									label="Learn More"
									onClick={() => offboardUserStayLicensed(props.userToEdit.endUserId)}
								/>
							</Box>

							<Typography type="h4" variant={['center']}>
								If you're sure, click "Yes" below to request your offboarding information.
							</Typography>
						</ModalBody>
						<ModalFooter center={true} gap={true}>
							<Button
								variant="outlined"
								size="lg"
								primary={false}
								label="Cancel"
								onClick={() => setShowOffboardingModal(false)}
							/>
							<Button
								variant="contained"
								size="lg"
								label={'Yes'}
								onClick={() => offboardUser(props.userToEdit.endUserId)}
							/>
						</ModalFooter>
					</Modal>
				)}
			</div>
		</div>
	)
}

export default DocsAndForms
